.testbox{
  width: fit-content;
  
  background: #2c3d4e;
  color: white;
  font-family: Outfit;
  
  padding-left: 5px;
  padding-right: 5px;
  font-size: 36px;
  
  padding-top: 0;


  
}
.testbox h1{
  font-size: 36px;
  font-weight: bolder;
  margin-top: 0px;
  line-height: 1.7;
  margin-bottom: 0px;
}
.extrabox{
  width: 20px;
  background: #678;
}

.testboxcontainer{
  width: 450px;
    display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  height: fit-content;
  transform: skew(-20deg);
  margin: auto;
}


.grid-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 50px;
    padding-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 50px;
    

    /* background: hsla(173, 100%, 98%, 1);

background: linear-gradient(90deg, hsla(173, 100%, 98%, 1) 0%, hsla(300, 75%, 97%, 1) 98%);

background: -moz-linear-gradient(90deg, hsla(173, 100%, 98%, 1) 0%, hsla(300, 75%, 97%, 1) 98%);

background: -webkit-linear-gradient(90deg, hsla(173, 100%, 98%, 1) 0%, hsla(300, 75%, 97%, 1) 98%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F6FFFE", endColorstr="#FDF1FD", GradientType=1 ); */
  }
  
  .grid-item {
    margin: 20px;
    background-color: #fff;
    border: 0.5px solid grey;
    width: 350px;
    height: 357px;
    font-size: 30px;
    border-radius: 3px;
    text-align: center;
    transition: all 0.3s ease;

    border: 1px solid rgba(255, 255, 255, .25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(135, 206, 235, 0.5);

  }
  
  .grid-item:hover {
    transform: translateY(-10px) scale(1.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(135, 206, 235, 0.5);

  }
  
  .grid-item img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 10px;
  }
  
  .grid-item h6 {

    margin: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: medium;
    font-weight: 600;
  }
  
  .names {
    width: 70%;
    background-color: #2c3d4e;
    color: #fff;
    margin-top: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
  }
  
  .carname {
    margin-top: 3px;
  }
  
