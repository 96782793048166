.container-contact {
  
    width: 70%;
    max-width: 800px;
    margin: 0 auto;
    padding:20px 20px;
    margin-top: 30px;
    border-radius: 20px;
    
  }
  
  .heading {
    text-align: center;
  }
  
  .form-row-contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .form-group-contact {

    width: 30%;
    margin-bottom: 1rem;
  }
  
  .form-group-contact label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-group-contact input {
    width: 100%;
    padding: 0.5rem;
    /* border: 1px solid #ccc; */
    border: 1px solid rgba(162, 215, 251, 0.25);

    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
  }
  .form-group-contact textarea{
    border-radius: 20px;
    padding: 20px 20px;
    margin-top: 10px;
    padding-bottom: 0;
    
  }
  
  
  
  @media screen and (max-width: 768px) {
    .form-row-contact {
      flex-direction: column;
    }
  
    .form-group-contact {
      width: 100%;
    }
  }
  .contact_info_item{
    transition: all 0.3s ease;

    /* border: 0.5px solid rgba(255, 255, 255, .25); */
    border: 0.5px solid rgba(162, 215, 251, 0.25);
  border-radius: 20px;
  background-color: #fff;
  
  /* box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25); */
  box-shadow: 0 0 0.2px 0.2px rgba(162, 215, 251, 0.25);


  /* backdrop-filter: blur(1px); */
  }

  .contact_info_item:hover {
    transform: translateY(-10px) scale(1.1);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  }