.imgcontainer{
  width: 70%;
  
  height:720px;
  
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  object-fit:contain;
  
}
.textcontainer{
  /* flex-wrap: wrap; */
  background-color: yellowgreen;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 27%;
  
}
.textcontainer h1 , .textcontainer h2{
  word-wrap: break-word;
}



.img{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  
  
}
.exit{
  font-family: 'Racing Sans One';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  

  
}

.kmcontainer{
  margin-top: 30px;
}
.kmcontainer h1{
  text-align: center;
  align-items: center;
  font-family: 'Racing Sans One';
  font-weight: 200;
}
.km{
  text-align: center;
  align-items: center;
  font-family: 'Orbitron';
  font-weight: 100;
  font-size: 100px;
}
.herocontainer{
  text-align: center;
  align-items: center;
  font-weight: 100;
  font-size: 100px;
  font-family: 'Racing Sans One';
  
}
.carherocontainer{
  display:flex ;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
.heroimgcontainer{
  width: 100%;
  height: 280px;
  object-fit: contain;
  overflow: hidden;
}
.heroimgcontainer img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.cardetail{
  width: 30%;
}
.newscontainer{
  width: 70%;
  margin: auto;
  text-align: center;
}
.mainnewscontainer{
  display: flex;
  
  flex-direction: row;
}
.articlecontainer{
  flex-wrap: wrap;
  word-wrap: break-word;
  width: 65%;
  
  padding: 30px;
  flex-shrink: 0;
}

.newsimgcontainer{
  margin: auto;
  width: 25%;
  border-radius: 15px;
  height: 100%;
  align-items: center;
  object-fit: contain;
  overflow: hidden;
  
}
.newsimgcontainer img{
  object-fit: cover;
  width: 100%;

  
}

.map{
  height :400px;
  width: 80%;
  margin: auto;
  
  border-radius: 20px;
  
  padding: 10px;
}

.map iframe{
  height: 95%;
  width: 95%;
  border: 0;
  border-radius: 10px;
  
}
.mapscontiner{
  text-align: center;
  margin-top: 50px;
  
}

model-viewer {
  width: 100%;
  height: 400px;
  
  
}

.vrdiagram {
  width: 60%;
  
}

.vrcontainer {
    font-family: "Racing Sans One";
  width: 100%;
  display: flex;
  flex-direction: row;
}

.vrtext {
    font-family: "Racing Sans One";
  display: flex;
  font-size: 60px;
  font-weight: 400;
  flex-direction: column;
  width: 40%;
  text-align: center;
  word-wrap: break-word;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;}

.plgm {
    	width: 400px;
	height: 100px;
	transform: skew(-20deg);
	background: black;
}
.vrtext h2{
    letter-spacing: 2px;
    margin-top: 0;
    padding-top: 0;
}
.vrtext h1{
    margin-bottom: 20px;
    color: black;
}
  .vrtext h2 a {
    margin-top: 0;
    text-decoration: none;
    color:#fff;
  }


